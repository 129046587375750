import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
// import Cookies from 'universal-cookie'

import Meta from 'components/Meta'
import Hero from 'components/home/Hero'
import BenefitsCols from 'components/home/BenefitsCols'
import Reviews from 'components/home/Reviews'
import Curve from 'components/home/Curve'
import Approach from 'components/home/Approach'
import Expect from 'components/home/Expect'
import Environment from 'components/home/Environment'
import Start from 'components/Start'
import Button from 'components/Button'
import BandPromo from 'components/BandPromo'
// import { useEffect } from 'react'
import { pullParams } from 'utils/helpers'
import useTrack from 'utils/hooks/useTrack'

export default function Home(params) {
    const { t } = useTranslation('home')
    const router = useRouter()
    const trackEvent = useTrack()
    // const cookies = new Cookies();

    const data = {
        name: t('home_hero_name'),
        description: t('home_hero_description'),
        slogan: t('home_hero_slogan'),
        image: '/images/hero.jpg'
    }

    const handleClick = () => {
        trackEvent('Web Campaign Landing CTA Click', {
            ...pullParams(),
            campaign_name: params.name
        })

        router.push('/start')
    }

    const renderButtons = () => {
        return (
            <Button onClick={handleClick}>Get my plan</Button>
        )
    }

    // useEffect(() => {
    //     const {discount, code, type } = params

    //     cookies.set(
    //         'vhPromo',
    //         {discount, code, type },
    //         {
    //             path: '/',
    //             expires: new Date(Date.now() + 86400000) // one day
    //         }
    //     )
    // },[])

    return (
        <>
            <Meta title={data.name} description={data.description} noindex={true} />
            {!!params.show_line && <BandPromo text={params.line_text} discount={params.discount} />}
            <Hero data={data} buttons={renderButtons} />
            <BenefitsCols />
            <Approach/>
            <Expect/>
            <Environment buttons={renderButtons}/>
            <Curve />
            <Reviews />
            <Start buttons={renderButtons} />
        </>
    )
}