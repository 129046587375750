import useTranslation from 'next-translate/useTranslation'
import styled from 'styled-components'
import UniversalCookies from 'universal-cookie'
import { useRouter } from 'next/router'

import Meta from 'components/Meta'
import Hero from 'components/home/Hero'
import Footer from 'components/Footer'
import device from 'utils/device'
// import { useEffect } from 'react'
import BandPromo from 'components/BandPromo'
// import { useEffect } from 'react'

export default function Home(params) {
    const cookies = new UniversalCookies()
    const router = useRouter()
    const type = 'light'//router.query.type || 'light'

    const { t } = useTranslation('home')

    const data = {
        name: t('home_hero_name'),
        description: t('home_hero_description')+" Together, let's shape your personalized longevity strategy.",
        slogan: t('home_hero_slogan'),
        image: '/images/hero.jpg'
    }

    // useEffect(()=>{
    //     if (type) {
    //         // document.body.style.setProperty('background', 'var(--color-blue)')
    //     }
    // },[type])

    // useEffect(() => {
    //     const {discount, code, type } = params

    //     cookies.set(
    //         'vhPromo',
    //         {discount, code, type },
    //         {
    //             path: '/',
    //             expires: new Date(Date.now() + 86400000) // one day
    //         }
    //     )
    // },[])

    const handleClick = value => {
        cookies.set(
            'vhAnswers',
            { gender: value },
            {
                path: '/',
                expires: new Date(Date.now() + 86400000) // one day
            }
        )

        router.push('/quiz')
    }

    const renderButtons = () => {
        return (
            <>
                <Buttons>
                    <Button type={type} onClick={() => handleClick('male')}>Male</Button>
                    <Button onClick={() => handleClick('female')}>Female</Button>
                    <Button type={type} onClick={() => handleClick('other')}>Other</Button>
                </Buttons>
            </>
            
        )
    }

    return (
        <>
            <Meta title={data.name} description={data.description} noindex={true} />
            {!!params.show_line && <BandPromo text={params.line_text} discount={params.discount} />}
            <Hero type={type} data={data} showArrow={false} buttons={renderButtons} slogan="Tell us a bit about yourself:" />
            <Bg/>
            <br/><br/><br/><br/>
            <Footer naked={true} type={type} />
        </>
    )
}

const Bg = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    background: var(--color-blue);
    width: 100%;
    height: 100%;
    z-index: -1;
`

const Buttons = styled.div`
    display: flex;
    margin-top: -0.12rem;
    color: var(--color-dark);
    ${device.mobile} {
        display: block;
    }
`

const Button = styled.div`
    display: flex;
    height: 0.48rem;
    font-size: 0.16rem;
    margin-right: 0.08rem;
    border-radius: 0.24rem;
    font-weight: 600;
    align-items: center;
    padding: 0 0.36rem;
    text-align: center;
    justify-content: center;
    background: ${props => props.type === 'light' ? 'var(--color-purple)' : 'var(--color-blue)'};
    cursor: pointer;
    user-select: none;
    transition: background 150ms ease-in-out, border 150ms ease-in-out;
    &:hover {
        background: ${props => props.type === 'light' ? 'var(--color-purple-hover)' : 'var(--color-blue-hover)'};
    }
    &:nth-child(2) {
        background: var(--color-orange);
        &:hover {
            background: var(--color-orange-hover);
        }
    }
    &:nth-child(3) {
        background: transparent;
        border: 1px solid ${props => props.type === 'light' ? 'rgba(255,255,255,.24)' : 'var(--color-border)'};
        color: ${props => props.type === 'light' ? '#fff' : 'var(--color-dark)'};
        &:hover {
            border: 1px solid ${props => props.type === 'light' ? 'rgba(255,255,255,.36)' : '#bbb'};
        }
    }
    ${device.mobile} {
        width: 100%;
        margin-bottom: 0.12rem;
    }
`