
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import O1 from 'components/promos/o1';
import O2 from 'components/promos/o2';
import { getData } from 'utils/api';
import UniversalCookies from 'universal-cookie';
import { useEffect } from 'react';
export default function Page(props) {
    const cookies = new UniversalCookies();
    useEffect(() => {
        const { discount, code, type, line_text, show_line, name } = props;
        cookies.set('vhPromo', { discount, code, type, line_text, show_line, name }, {
            path: '/',
            expires: new Date(Date.now() + 86400000) // one day
        });
    }, []);
    return props.type === 'mini' ? <O1 {...props}/> : <O2 {...props}/>;
}
async function getServerSideProps(context) {
    const code = context.params.code;
    return getData(`promotion/${code}`, true, true);
}

    async function __Next_Translate__getServerSideProps__19574939a03__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/[...code]',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__19574939a03__ as getServerSideProps }
  